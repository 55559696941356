<template>
  <div style="height: 100%">
    <van-cell-group inset style="margin-top: 10px">
      <van-cell title="出团详情" size="large">
        <template #right-icon>
          <van-button type="info" size="small">导出行程单</van-button>
        </template>
      </van-cell>
      <van-cell
        title="导游"
        :value="tour.guide"
        clickable
        is-link
        :to="`/wode`"
      />
      <van-cell title="出团日期" :value="tour.start_date"> </van-cell>
      <van-cell title="收团日期" :value="tour.end_date"> </van-cell>
    </van-cell-group>
    <van-cell-group inset style="margin-top: 10px">
      <van-cell title="行程" value="" />
      <van-steps direction="vertical">
        <van-step v-for="(item, index) in travelListNodes" :key="index">
          <van-cell
            :title="item.type"
            :label="item.name"
            :value="item.description"
          >
          </van-cell>
        </van-step>
      </van-steps>
    </van-cell-group>
    <van-cell-group inset style="margin-top: 10px; margin-bottom: 100px">
      <van-cell title="游客列表" size="large" />
      <van-cell
        v-for="(item, index) in tour.tourists"
        :key="'tourist:' + index"
        :title="item.name"
        :value="item.gender"
        :label="item.mobile"
      >
      </van-cell>
    </van-cell-group>
    <van-row class="action_btn">
      <van-button type="primary" style="width: 100%" @click="startTour()">开始行程</van-button>
    </van-row>
  </div>
</template>

<script>
import api from "@/services/apis.js";
import { Dialog } from 'vant';
import { Toast } from 'vant';
export default {
  mounted() {
    let _self = this;
    let tourId = this.$route.params.id;
    if (tourId === undefined) {
      this.edit = true;
    } else {
      api.get_travel_lists_info(tourId).then(function (res) {
        let element = res.data.data;
        if (parseInt(element.id) == tourId) {
          _self.tour = element;
        }
      });
      api.get_travel_lists_nodes(tourId).then((res) => {
        _self.travelListNodes = res.data.nodes;
      });
    }
  },
  data() {
    return {
      edit: false,
      tour: {},
      canStart: true,
      travelListNodes: [],
    };
  },
  methods: {
    submit() {
      this.$router.push("/tour");
    },
    startTour() {
      const _self = this
      api.get_travel_lists().then((res) => {
        if(res.data.status === 200) {
          res.data.lists.forEach((tour) => {
            if(tour.status === 1) {
              _self.canStart = false
              Dialog.alert({
                title: '提示',
                message: '当前有还未结束的行程，请先结束该行程',
              })
            }
          })
        }
      })
      if(_self.canStart) {
        Dialog.confirm({
          title: '提示',
          message: '确定开始行程？'
        }).then(() => {
          api.traval_lists_change_status({
            travel_list_id: _self.tour.id,
            status: 1
          }).then((res) => {
            if(res.data.status === 200) {
              Toast('成功开始行程')
              _self.$router.push('/tour')
            }
          })
        }).catch(() => {
          console.log('cancel tour');
        })
      }
    },
  },
};
</script>

<style>
.action_btn {
  position: fixed;
  bottom: 50px;
  width: 100%;
  left: 0px;
}
</style>